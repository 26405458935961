<template>
  <Layout class="gradient-bkg" id="homepage-content">
    <VContainer fluid class="water-bkg">
      <VLayout column fill-height>
        <VLayout justify-center>
          <VFlex xs8 sm12 class="text-center">
            <h1 class="logo-text">
              aestell
            </h1>
            <h3 class="ml11 logo-subtitle">
              <span class="text-wrapper">
                <span class="line line1 hidden-xs-only"></span>
                <span ref="letters" class="letters">
                  <span
                    v-for="(letter, index) in titleAnimate"
                    :key="`'subtitle'-${index}`"
                    class="letter"
                    v-html="letter !== ' ' ? letter : '&nbsp;'"
                  ></span
                ></span>
              </span>
            </h3>
          </VFlex>
        </VLayout>
        <VLayout>
          <VFlex xs12 style="height: 385px;" />
        </VLayout>
        <VLayout row wrap align-end justify-center class="btn-wrapper">
          <VBtn
            to="/payment"
            ripple
            color="#7870FE"
            min-width="130px"
            min-height="38px"
            class="btn-index ma-2"
            >Start Now</VBtn
          >
          <VBtn
            :to="{ name: 'dashboard' }"
            ripple
            min-width="130px"
            min-height="38px"
            color="#ffffff"
            class="btn-index btn-index-admin ma-2"
            >Admin Console</VBtn
          >
        </VLayout>
      </VLayout>
    </VContainer>
    <VCard class="nuxt-box ml-5 mb-5 text-center hidden-sm-and-down">
      <VCardText class="nuxt-chip">
        <VChip>Powered By AI</VChip> <br />
        <a href="http://nuxtjs.org"
          ><img src="~/assets/img/built-with-nuxt.svg" class="nuxt-logo"
        /></a>
      </VCardText>
    </VCard>
  </Layout>
</template>
<script>
import anime from 'animejs'
import Layout from '~/components/layout/Layout'

export default {
  components: {
    Layout
  },
  data: () => ({
    titleAnimate: 'Next level disruptive, built to do great things.'
  }),
  mounted() {
    anime
      .timeline()
      .add({
        targets: '.ml11 .line',
        scaleY: [0, 1],
        opacity: [0.5, 1],
        easing: 'easeOutExpo',
        duration: 350
      })
      .add({
        targets: '.ml11 .line',
        translateX: [0, this.$refs.letters.offsetWidth + 7],
        easing: 'linear',
        duration: 1300,
        delay: 100
      })
      .add(
        {
          targets: '.ml11 .letter',
          opacity: [0, 1],
          easing: 'easeOutExpo',
          duration: 100,
          delay(el, i) {
            return 26 * (i + 1)
          }
        },
        '-=1250'
      )
      .add({
        targets: '.ml11 .line',
        opacity: 0,
        duration: 10,
        easing: 'easeOutExpo'
      })
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables.scss';

.ml11 {
}

.ml11 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.05em;
}

.ml11 .line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: #486bb2;
  transform-origin: 0 50%;
}

.ml11 .line1 {
  top: 0;
  margin-left: -8px;
  left: 0;
}

.ml11 .letter {
  opacity: 0;

  display: inline-block;
}
.text-block-left {
  margin-left: 14px;
  padding-top: 376px;
  position: absolute;
  max-width: 200px;
}
.btn-index {
  font-family: 'Agane', 'Inter var', sans-serif;
  font-size: 16px;
  color: #ffffff;
}
.btn-index-admin {
  color: #7870fe;
}
.btn-index-admin:before {
  background-color: #ffffff;
}
.logo-subtitle {
  color: #666;
  font-family: 'Quicksand Medium', 'Inter var', sans-serif;
  font-size: 22px;
  font-weight: normal;
}
.nuxt-logo {
  width: 179px;
}
.nuxt-box {
  position: absolute;
  background-color: #f7f8fa;
  z-index: 1;
  bottom: 0;
}
.water-bkg {
  background: url('~assets/img/stella-bkg.svg') 50% 79% no-repeat,
    url('~assets/img/index-bkg.png') 100% 100% no-repeat,
    url('~assets/img/stella-bkg-shadow.svg') 50% 79% no-repeat;
  background-size: 550px 440px, 72% 38%, 550px 440px;
  height: 100%;
  z-index: 1;
}
.btn-wrapper {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-direction: column;
  }
}
</style>


<style lang="scss">
// custom position for footer to enable consistent padding, and giving water-bkg full height

#homepage-content {
  @media (min-width: 1000px) {
    overflow-y: hidden;
    height: 100vh;

    #footer {
        position: absolute;
        bottom: 16px;
        align-items: flex-end;
        padding-bottom: 0;
        right: 0;
        background-color: transparent !important;
    }

    .v-content__wrap {
      position: static;
    }
  }
}
</style>